import React from "react";

import Header from "./Header";
import "../css/amazonmarket.css";
import Footer from "./Footer";
function amazonmarket() {
  return (
    <div>
      {" "}
      <Header></Header>
      <div className="section0">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h4>Amazon Marketing Services</h4>
              <p>Our Amazon Marketing Agency Delivers Customized Solutions</p>
              <a href="/#" className="btn btn-outline-success">
                GET MY FREE PROPOSAL{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="wordp wwordp">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <img
                src="https://thriveagency.com/files/amazon-marketing-new-resize@2.jpg"
                alt=""
              />
            </div>
            <div className="col-6">
              <p>
                <b>Amazon Account Management</b> <br />
                If you’ve performed a Google search on “what is WordPress,” you
                most likely did so after having experienced a WordPress website.
                WordPress is a website creation tool that powers more than 38
                percent of the web — that means approximately 1 in 3 websites
                trust WordPress services. If you searched “how to design a
                WordPress website,” then you’ll find that this platform is
                considered incredibly user-friendly and easy to understand.
                <br />
                <br />
                The vision of the original WordPress development team was to
                create a digital space where users can share their story freely.
                The team ensured that users could:
              </p>
            </div>

            <div className="col-6">
              <p>
                <b>Pricing Strategy & Testing</b> <br />
                If you’ve performed a Google search on “what is WordPress,” you
                most likely did so after having experienced a WordPress website.
                WordPress is a website creation tool that powers more than 38
                percent of the web — that means approximately 1 in 3 websites
                trust WordPress services. If you searched “how to design a
                WordPress website,” then you’ll find that this platform is
                considered incredibly user-friendly and easy to understand.
                <br />
                <br />
                The vision of the original WordPress development team was to
                create a digital space where users can share their story freely.
                The team ensured that users could:
              </p>
            </div>
            <div className="col-6">
              <img
                src="https://thriveagency.com/files/amazon-marketing-img.png"
                alt=""
              />
            </div>
            <div className="col-6">
              <img
                src="https://thriveagency.com/files/Amazon-Catalog-e1573155831602.png"
                alt=""
              />
            </div>
            <div className="col-6">
              <p>
                <b>Amazon Account Management</b> <br />
                If you’ve performed a Google search on “what is WordPress,” you
                most likely did so after having experienced a WordPress website.
                WordPress is a website creation tool that powers more than 38
                percent of the web — that means approximately 1 in 3 websites
                trust WordPress services. If you searched “how to design a
                WordPress website,” then you’ll find that this platform is
                considered incredibly user-friendly and easy to understand.
                <br />
                <br />
                The vision of the original WordPress development team was to
                create a digital space where users can share their story freely.
                The team ensured that users could:
              </p>
            </div>

            <div className="col-6">
              <p>
                <b>Pricing Strategy & Testing</b> <br />
                If you’ve performed a Google search on “what is WordPress,” you
                most likely did so after having experienced a WordPress website.
                WordPress is a website creation tool that powers more than 38
                percent of the web — that means approximately 1 in 3 websites
                trust WordPress services. If you searched “how to design a
                WordPress website,” then you’ll find that this platform is
                considered incredibly user-friendly and easy to understand.
                <br />
                <br />
                The vision of the original WordPress development team was to
                create a digital space where users can share their story freely.
                The team ensured that users could:
              </p>
            </div>
            <div className="col-6">
              <img src="https://thriveagency.com/files/14515@2x.png" alt="" />
            </div>
            <div className="col-6">
              <img src="https://thriveagency.com/files/Group-26.png" alt="" />
            </div>
            <div className="col-6">
              <p>
                <b>Amazon Account Management</b> <br />
                If you’ve performed a Google search on “what is WordPress,” you
                most likely did so after having experienced a WordPress website.
                WordPress is a website creation tool that powers more than 38
                percent of the web — that means approximately 1 in 3 websites
                trust WordPress services. If you searched “how to design a
                WordPress website,” then you’ll find that this platform is
                considered incredibly user-friendly and easy to understand.
                <br />
                <br />
                The vision of the original WordPress development team was to
                create a digital space where users can share their story freely.
                The team ensured that users could:
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="section5 section555 sectiono55">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h4>Amazon Marketing Services</h4>
              <p className="p">
                Looking for an Amazon Agency that's Primed to Deliver?
              </p>
            </div>
            <div className="col-4">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-4">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-4">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-4">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-4">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-4">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-4">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-4">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-4">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="comman commann">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <a href="/#" className="btn btn-outline-success">
                GROW MY LEADS NOW
              </a>
              <p className="pp">
                <b>Search Engine Optimization FAQs</b>
              </p>
            </div>
            <div className="col-12">
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="/#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Accordion Item #1
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="/#accordionExample"
                  >
                    <div class="accordion-body">
                      <strong>This is the first item's accordion body.</strong>{" "}
                      It is shown by default, until the collapse plugin adds the
                      appropriate classes that we use to style each element.
                      These classes control the overall appearance, as well as
                      the showing and hiding via CSS transitions. You can modify
                      any of this with custom CSS or overriding our default
                      variables. It's also worth noting that just about any HTML
                      can go within the <code>.accordion-body</code>, though the
                      transition does limit overflow.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="/#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Accordion Item #2
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="/#accordionExample"
                  >
                    <div class="accordion-body">
                      <strong>This is the second item's accordion body.</strong>{" "}
                      It is hidden by default, until the collapse plugin adds
                      the appropriate classes that we use to style each element.
                      These classes control the overall appearance, as well as
                      the showing and hiding via CSS transitions. You can modify
                      any of this with custom CSS or overriding our default
                      variables. It's also worth noting that just about any HTML
                      can go within the <code>.accordion-body</code>, though the
                      transition does limit overflow.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingThree">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="/#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Accordion Item #3
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="/#accordionExample"
                  >
                    <div class="accordion-body">
                      <strong>This is the third item's accordion body.</strong>{" "}
                      It is hidden by default, until the collapse plugin adds
                      the appropriate classes that we use to style each element.
                      These classes control the overall appearance, as well as
                      the showing and hiding via CSS transitions. You can modify
                      any of this with custom CSS or overriding our default
                      variables. It's also worth noting that just about any HTML
                      can go within the <code>.accordion-body</code>, though the
                      transition does limit overflow.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fra frra">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <img
                src="https://thriveagency.com/files/awardimage2020.jpg"
                alt=""
              />
              <h1>Ready to Get Started?</h1>
              <p>Schedule a call with our Shopify SEO experts today.</p>
              <a href="/#" className="btn btn-outline-success">
                LETS'S TALK
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default amazonmarket;
