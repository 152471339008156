import React from "react";
import Header from "./Header";
import "../css/wwd.css";
import Footer from "./Footer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 2, // Number of slides to show at once
  slidesToScroll: 1, // Number of slides to scroll at a time
  // You can add more settings and customize it further
};
function wwd() {
  return (
    <div className="maine">
      {" "}
      <Header></Header>
      <div className="section0">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h4>WordPress Website Design</h4>
              <p>Boost Sales With Website Solutions Tailored to Your Needs</p>
              <a href="/#" className="btn btn-outline-success">
                GET MY FREE PROPOSAL{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="wordp">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>What Is WordPress?</h2>
              <p className="pp">
                Drive Results With the Most Popular Content Management System
              </p>
            </div>
            <div className="col-lg-6">
              <p>
                If you’ve performed a Google search on “what is WordPress,” you
                most likely did so after having experienced a WordPress website.
                WordPress is a website creation tool that powers more than 38
                percent of the web — that means approximately 1 in 3 websites
                trust WordPress services. If you searched “how to design a
                WordPress website,” then you’ll find that this platform is
                considered incredibly user-friendly and easy to understand.
                <br />
                <br />
                The vision of the original WordPress development team was to
                create a digital space where users can share their story freely.
                The team ensured that users could:
              </p>
            </div>
            <div className="col-lg-6">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXNaLcNmt30ukyvF_P4m7pFgHaCa0ePSJlhkFV8CLXxA&s"
                alt=""
              />
            </div>
            <div className="col-12">
              <p>
                In WordPress terms, themes decide your website’s look and feel
                while plugins add functionality, such as adding a contact form
                or installing search engine optimization (SEO) tools. Generally,
                a WordPress web designer focuses on your branding and visual
                elements while a WordPress web developer deals with the code.
                <br />
                The possibilities are endless with a WordPress website, but you
                need a professional WordPress design and development team to
                make your vision come to life.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="wordp wordpp">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>Why Your Business Should Use WordPress Web Design</h2>
              <p className="pp">
                Become Easily Found Online With a Site Optimized for Search
              </p>
            </div>
            <div className="col-lg-6">
              <p>
                We’ve covered “what is WordPress” and “what is WordPress used
                for” but to understand its benefits, we’ll talk about how
                WordPress web design is advantageous for your business — and,
                no, you don’t have to be a WordPress designer or experienced
                WordPress developer to understand it.
                <br />
                To get more sales, your website must first be found online.
                Then, your web design needs to be eye-catching and showcase all
                the information your potential customers need. It must have
                intuitive navigation and provide the best browsing experience.
                <br />
                Through the eyes of a WordPress expert, everything your business
                site should be is absolutely possible and easily developed with
                WordPress design services.
                <br /> <br />
                For a top-performing website, you need WordPress development.
                Here’s why:
              </p>
            </div>
            <div className="col-lg-6">
              <img
                src="https://thriveagency.com/wp-content/themes/thrive-agency/images/wordpress-design-image.svg"
                alt=""
              />
            </div>
            <div className="col-12">
              <p>
                In WordPress terms, themes decide your website’s look and feel
                while plugins add functionality, such as adding a contact form
                or installing search engine optimization (SEO) tools. Generally,
                a WordPress web designer focuses on your branding and visual
                elements while a WordPress web developer deals with the code.
                <br />
                The possibilities are endless with a WordPress website, but you
                need a professional WordPress design and development team to
                make your vision come to life.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="section5 section555 sectiono5 jackl">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wordp wordpp">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <p>
                We’ve covered “what is WordPress” and “what is WordPress used
                for” but to understand its benefits, we’ll talk about how
                WordPress web design is advantageous for your business — and,
                no, you don’t have to be a WordPress designer or experienced
                WordPress developer to understand it.
                <br />
                To get more sales, your website must first be found online.
                Then, your web design needs to be eye-catching and showcase all
                the information your potential customers need. It must have
                intuitive navigation and provide the best browsing experience.
                <br />
                Through the eyes of a WordPress expert, everything your business
                site should be is absolutely possible and easily developed with
                WordPress design services.
                <br /> <br />
                For a top-performing website, you need WordPress development.
                Here’s why:
              </p>
            </div>
            <div className="col-lg-6">
              <img
                src="https://thriveagency.com/wp-content/themes/thrive-agency/images/wordpress-design-image.svg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="caro">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>Our Latest Web Design Projects</h2>
            </div>
            <div className="col-12">
              <div className="row">
                <Slider {...settings} className="slide">
                  <div className="col-4">
                    <div>
                      <img
                        src="https://miro.medium.com/v2/resize:fit:871/1*9YJw0zVY1pIK41eTErjzgQ.jpeg"
                        alt=""
                      />
                      <h3>Softroc</h3>
                    </div>
                  </div>
                  <div className="col-4">
                    <div>
                      <img
                        src="https://miro.medium.com/v2/resize:fit:871/1*9YJw0zVY1pIK41eTErjzgQ.jpeg"
                        alt=""
                      />
                      <h3>Softroc</h3>
                    </div>
                  </div>
                  <div className="col-4">
                    <div>
                      <img
                        src="https://miro.medium.com/v2/resize:fit:871/1*9YJw0zVY1pIK41eTErjzgQ.jpeg"
                        alt=""
                      />
                      <h3>Softroc</h3>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section4  sectionn44">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>
                Professional WordPress Websites Sure to Skyrocket Your Business
              </h2>
              <p className="pp">
                Grow Your Customer Base With An Improved Online Presence
              </p>
              <p>
                For more than 15 years, we’ve built WordPress websites optimized
                to drive online success. Our WP experts use tried-and-true
                techniques to improve your website rankings, drive traffic to
                your site and increase your sales qualified leads. Here’s what
                our happy clients have to say about us:
              </p>
            </div>
            <div className="col-lg-4 col-md-6 mt-5">
              <div className="box">
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9aCMeX2cx-RFDbk_zNnKZR-lFxvQ_aF4xHA&usqp=CAU"
                  alt=""
                />
                <div className="co">
                  <h6>
                    <a href="/#">CONSTRUCTION COMPANY</a>
                  </h6>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p>
                      <span> +800%</span>
                      <br />
                      Online Leads
                    </p>
                  </div>
                  <div className="col-6">
                    <p>
                      <span> +800%</span>
                      <br />
                      Online Leads
                    </p>
                  </div>
                </div>
                <p>
                  Thrive has gone above and beyond and it’s allowed me to focus
                  on other aspects of business development. Thrive understands
                  the intricate needs of what we want to accomplish.”
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-5">
              <div className="box">
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9aCMeX2cx-RFDbk_zNnKZR-lFxvQ_aF4xHA&usqp=CAU"
                  alt=""
                />
                <div className="co">
                  <h6>
                    <a href="/#">CONSTRUCTION COMPANY</a>
                  </h6>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p>
                      <span> +800%</span>
                      <br />
                      Online Leads
                    </p>
                  </div>
                  <div className="col-6">
                    <p>
                      <span> +800%</span>
                      <br />
                      Online Leads
                    </p>
                  </div>
                </div>
                <p>
                  Thrive has gone above and beyond and it’s allowed me to focus
                  on other aspects of business development. Thrive understands
                  the intricate needs of what we want to accomplish.”
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-5">
              <div className="box">
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9aCMeX2cx-RFDbk_zNnKZR-lFxvQ_aF4xHA&usqp=CAU"
                  alt=""
                />
                <div className="co">
                  <h6>
                    <a href="/#">CONSTRUCTION COMPANY</a>
                  </h6>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p>
                      <span> +800%</span>
                      <br />
                      Online Leads
                    </p>
                  </div>
                  <div className="col-6">
                    <p>
                      <span> +800%</span>
                      <br />
                      Online Leads
                    </p>
                  </div>
                </div>
                <p>
                  Thrive has gone above and beyond and it’s allowed me to focus
                  on other aspects of business development. Thrive understands
                  the intricate needs of what we want to accomplish.”
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section5 section555 sectiono55">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h4>WordPress Web Design Services</h4>
              <p className="p">
                Attract More Customers With a Top-Performing Website
              </p>
            </div>
            <div className="col-lg-4 mt-5 col-md-6">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mt-5 col-md-6">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mt-5 col-md-6">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mt-5 col-md-6">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mt-5 col-md-6">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mt-5 col-md-6">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mt-5 col-md-6">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mt-5 col-md-6">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mt-5 col-md-6">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section5 section555 sectiono5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h4>Why Hire a WordPress Web Design Company</h4>
              <p className="p">
                Stay Ahead of Your Competition by Entrusting our Experts
              </p>
              <p>
                If you want to stand out and be noticed by your target audience,
                it will take a lot more than standard templates and basic site
                functionality. Your biggest competitor is probably working with
                a top WordPress website design agency — and you should be, too.
                <br />
                Here are the advantages of hiring a WordPress company:
              </p>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section5 section555 sectiono55 sectionpp">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h4>WordPress Web Design Services</h4>
              <p className="p">
                Attract More Customers With a Top-Performing Website
              </p>
            </div>
            <div className="col-lg-4 mt-5 col-md-6">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mt-5 col-md-6">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mt-5 col-md-6">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mt-5 col-md-6">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mt-5 col-md-6">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mt-5 col-md-6">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mt-5 col-md-6">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mt-5 col-md-6">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mt-5 col-md-6">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default wwd;
