import React from "react";
import Header from "./Header";
import "../css/shopify.css";
import Footer from "./Footer";
function shopify() {
  return (
    <div className="mained">
      {" "}
      <Header></Header>
      <div className="section0">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h4>Shopify SEO</h4>
              <p>Scale Your eCommerce Business and Reach a Wider Audience</p>
              <a href="/#" className="btn btn-outline-success">
                GET MY FREE PROPOSAL{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="wordp wwordp">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>Build Your Own Scalable Online Store with Shopify</h2>
            </div>
            <div className="col-md-6">
              <img
                src="https://thriveagency.com/files/shopify-seo-img01.svg"
                alt=""
              />
            </div>
            <div className="col-md-6">
              <p>
                eCommerce has significantly changed the business scenario.
                Today, Shopify powers over a million businesses worldwide. This
                proves that eCommerce marketing is not just one strategy among
                many -- it is a global business necessity.
                <br />
                Shopify is considered among the leading eCommerce solutions and
                innovations that continue to redefine the global marketplace. In
                the last six months alone, Shopify generated over 46.45 million
                visits and received approximately 218 million orders in the
                previous year.
                <br />
                This all-in-one eCommerce platform now has more than 2 million
                active users, which contribute more than $183 billion in global
                economic activity. Overall, Shopify holds an impressive 31
                percent of the eCommerce market.
                <br />{" "}
              </p>
            </div>

            <div className="col-12">
              <p>
                In WordPress terms, themes decide your website’s look and feel
                while plugins add functionality, such as adding a contact form
                or installing search engine optimization (SEO) tools. Generally,
                a WordPress web designer focuses on your branding and visual
                elements while a WordPress web developer deals with the code.
                <br />
                The possibilities are endless with a WordPress website, but you
                need a professional WordPress design and development team to
                make your vision come to life.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="fra">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Ready to Get Started?</h1>
              <p>Schedule a call with our Shopify SEO experts today.</p>
              <a href="/#" className="btn btn-outline-success">
                LETS'S TALK
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="section5 section555 sectiono55 section55l">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h4>eCommerce SEO Services</h4>
              <p className="p">
                Build Trust and Increase Your eCommerce Conversions
              </p>
            </div>
            <div className="col-lg-4 col-md-6 ">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 ">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 ">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 ">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 ">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 ">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 ">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 ">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 ">
              <div className="row">
                <div className="col-3">
                  <i class="fa-solid fa-laptop"></i>
                </div>
                <div className="col-9">
                  <p className="pp">Keyword Research & Strategy</p>
                </div>
                <p>
                  Thrive is an SEO agency that understands your niche and can
                  boost your ranking for specific search terms. Your dedicated
                  SEO specialist will review the keywords your website is
                  currently ranking for, take your keyword wishlist and conduct
                  additional keyword research to build a list that makes the
                  most sense for your website and the competitive landscape.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default shopify;
